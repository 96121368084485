import gsap, {ScrollTrigger} from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.create({
	trigger: '.HomeAbout',
	onToggle: (self: any) => {
		gsap.set('.HomeBackground_movie', {visibility: self.isActive ? 'hidden' : 'visible'})
	}
});

gsap.set('body', {opacity: 0})
window.addEventListener('load', () => {
	gsap.to('body', {opacity: 1, duration: 1})
});
